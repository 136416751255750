<template>
  <div class="info" v-title data-title="Confirmation">
    <div class="cell">
      <el-result
        icon="success"
        title="Successfully submitted !"
        subTitle="We will contact you as soon as possible."
      >
        <!-- <template slot="extra">
          <span style="color: #67c23a; font-size: 17px">
            Please keep your recent electricity bill ready.Show it to the
            installer up on arrival.
          </span>
        </template> -->
      </el-result>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    // window.fbq("track", "ViewContent", {
    //   content_type: "Confirmation",
    //   content_name: "Confirmation",
    //   content_ids: ["10002"],
    //   content_category: "Confirmation",
    // });
    window.fbq("track", "CompleteRegistration");
  },
};
</script>

<style>
.el-result__subtitle p {
  font-size: 20px;
  color: inherit;
}
.el-result__extra {
  margin-top: 45px;
}
.info {
  height: calc(100vh - 100px);

  display: table;
}
.cell {
  display: table-cell;
  vertical-align: middle;
}
.el-main {
  height: 100%;
  background-color: #fff;
  display: flex;
  justify-content: space-around;
}
</style>
