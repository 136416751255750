<template>
  <div v-title data-title="GREEN SPARK">
    <div
      v-if="offsetwidth > 850"
      style="width: 100%; height: 100%; overflow: hidden; position: relative"
    >
      <img style="width: 100%" src="../assets/imgthree/PCNEW/2-1太阳能.jpg" alt="" />
      <img style="width: 100%" src="../assets/imgthree/PCNEW/2-2太阳能.jpg" alt="" />
      <img style="width: 100%" src="../assets/imgthree/PCNEW/2-3太阳能.jpg" alt="" />
      <img style="width: 100%" src="../assets/imgthree/PCNEW/2-4太阳能.jpg" alt="" />
      <div style="position: relative">
        <img
          style="width: 100%"
          src="../assets/imgthree/PC/join-now背景.jpg"
          alt=""
        />

        <img
          style="
            width: 25%;
            position: absolute;
            top: 60%;
            left: 50%;
            cursor: pointer;
            transform: translate(-50%, -50%);
          "
          @click="goinfoPC"
          src="../assets/imgthree/PC/join now.png"
          alt=""
        />
      </div>
      <img style="width: 100%" src="../assets/imgthree/PCNEW/底部.jpg" alt="" />
    </div>
    <div v-else style="width: 100%">
      <img
        style="width: 100%"
        src="../assets/imgthree/H5NEW/sp.jpg"
        alt=""
      />
      <div style="position: relative">
        <img
          style="width: 100%"
          src="../assets/imgthree/H5/背景图.jpg"
          alt=""
        />

        <img
          style="
             width: 65%;
            position: absolute;
            top: 70%;
            left: 50%;
            cursor: pointer;
            transform: translate(-50%, -50%);
          "
          @click="goinfoH5"
          src="../assets/imgthree/H5/join now.png"
          alt=""
        />
      </div>
      <img style="width: 100%" src="../assets/imgthree/H5NEW/底部手机版.jpg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    offsetwidth: {
      type: Number,
    },
  },
  mounted() {
    window.fbq("track", "ViewContent", {
      content_type: "Contact Us",
      content_ids: ["10005"],
      content_name: "Contact Us",
      content_category: "Contact Us",
    });
  },
  methods: {
    goinfoPC() {
      this.$router.push({name:'CustomerDetails',params:{pro:"PCSP"}});
      // window.sessionStorage.setItem("activeIndex", "/CustomerDetails");
      // this.$emit("fun", "/CustomerDetails");
    },
     goinfoH5() {
      this.$router.push({name:'CustomerDetails',params:{pro:"H5SP"}});
      // window.sessionStorage.setItem("activeIndex", "/CustomerDetails");
      // this.$emit("fun", "/CustomerDetails");
    },
  },
};
</script>

<style>
.text_bold {
  font-weight: 700;
}
</style>