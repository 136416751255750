<template>
  <div v-title data-title="GREEN SPARK">
    <div
      v-if="offsetwidth > 850"
      style="
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
        min-width: 1425px;
      "
    >
      <div style="width: 100%">
        <el-carousel
          :height="imgHeight"
          trigger="click"
          :interval="6000"
          arrow="always"
        >
          <el-carousel-item v-for="(item, index) in images" :key="index">
            <img
              ref="imgHeight"
              :src="item.idview"
              alt="Nodata"
              style="width: 100%"
              @load="imgLoad"
              @click="jump(item.name)"
            />
            <div>
              <div
                v-if="item.name == 'sp'"
                class="pchome"
                style="
                  border-style: solid;
                  border-color: transparent;
                  border-width: 5px;
                  position: absolute;
                  top: 20%;
                  right: 20.2%;
                  color: black;
                  width: 24%;
                  height: 62%;
                "
              >
                <div
                  style="font-size: 30px; color: rgb(34, 173, 56)"
                  :class="offsetwidth > 1600 ? 'mb_20' : ''"
                >
                  APPLY NOW
                </div>
                <el-form
                  ref="addUserForm1"
                  :model="addUserForm1"
                  :rules="addUserRule"
                  auto-complete="on"
                >
                  <el-form-item prop="FirstName">
                    <el-input
                      style="width: 100%"
                      clearable
                      v-model="addUserForm1.FirstName"
                      placeholder="First Name*"
                    ></el-input>
                  </el-form-item>
                  <el-form-item prop="LastName">
                    <el-input
                      style="width: 100%"
                      clearable
                      v-model="addUserForm1.LastName"
                      placeholder="Last Name*"
                    ></el-input>
                  </el-form-item>

                  <el-form-item prop="Phone">
                    <el-input
                      style="width: 100%"
                      clearable
                      v-model="addUserForm1.Phone"
                      placeholder="Phone Number*"
                    ></el-input>
                  </el-form-item>
                  <el-form-item prop="Address">
                    <el-input
                      style="width: 100%"
                      clearable
                      @focus="geolocate()"
                      name="autocomplete"
                      id="autocompletepc"
                      autocomplete="off"
                      v-model="addUserForm1.Address"
                    ></el-input>
                  </el-form-item>
                  <el-form-item prop="Email">
                    <el-input
                      style="width: 100%"
                      clearable
                      v-model="addUserForm1.Email"
                      placeholder="Email*"
                    ></el-input>
                  </el-form-item>
                </el-form>

                <el-button
                  :class="offsetwidth > 1600 ? 'mt_20' : ''"
                  @click="Submitform1(item.name)"
                  style="
                    width: 90%;
                    height: 13%;
                    background-color: rgb(34, 173, 56);
                    color: white;
                    border-radius: 10px;
                    font-size: 20px;
                  "
                  >Submit</el-button
                >
              </div>
              <div
                v-if="item.name == 'mac'"
                class="pchome"
                style="
                  border-style: solid;
                  border-color: transparent;
                  border-width: 5px;
                  position: absolute;
                  top: 20%;
                  right: 20.2%;
                  color: black;
                  width: 24%;
                  height: 62%;
                "
              >
                <div
                  style="font-size: 30px; color: rgb(34, 173, 56)"
                  :class="offsetwidth > 1600 ? 'mb_20' : ''"
                >
                  APPLY NOW
                </div>
                <el-form
                  ref="addUserForm2"
                  :model="addUserForm2"
                  :rules="addUserRule"
                  auto-complete="on"
                >
                  <el-form-item prop="FirstName">
                    <el-input
                      style="width: 100%"
                      clearable
                      v-model="addUserForm2.FirstName"
                      placeholder="First Name*"
                    ></el-input>
                  </el-form-item>
                  <el-form-item prop="LastName">
                    <el-input
                      style="width: 100%"
                      clearable
                      v-model="addUserForm2.LastName"
                      placeholder="Last Name*"
                    ></el-input>
                  </el-form-item>

                  <el-form-item prop="Phone">
                    <el-input
                      style="width: 100%"
                      clearable
                      v-model="addUserForm2.Phone"
                      placeholder="Phone Number*"
                    ></el-input>
                  </el-form-item>
                  <el-form-item prop="Address">
                    <el-input
                      style="width: 100%"
                      clearable
                      @focus="geolocate()"
                      name="autocomplete"
                      id="autocompletepc2"
                      autocomplete="off"
                      v-model="addUserForm2.Address"
                    ></el-input>
                  </el-form-item>
                  <el-form-item prop="Email">
                    <el-input
                      style="width: 100%"
                      clearable
                      v-model="addUserForm2.Email"
                      placeholder="Email*"
                    ></el-input>
                  </el-form-item>
                </el-form>

                <el-button
                  @click="Submitform2(item.name)"
                  :class="offsetwidth > 1600 ? 'mt_20' : ''"
                  style="
                    width: 90%;
                    height: 13%;
                    background-color: rgb(34, 173, 56);
                    color: white;
                    border-radius: 10px;
                    font-size: 20px;
                  "
                  >Submit</el-button
                >
              </div>
              <!-- <div
                v-if="item.name == 'home'"
                class="pchome"
                style="
                  border-style: solid;
                  border-color: transparent;
                  border-width: 5px;
                  position: absolute;
                  top: 20%;
                  right: 20.2%;
                  color: black;
                  width: 24%;
                  height: 62%;
                "
              >
                <div style="font-size: 30px; color: rgb(34, 173, 56)" :class="offsetwidth>1600?'mb_20':''">
                  APPLY NOW
                </div>
                <el-form
                  ref="addUserForm3"
                  :model="addUserForm3"
                  :rules="addUserRule"
                  auto-complete="on"
                >
                  <el-form-item prop="FirstName">
                    <el-input
                      style="width: 100%"
                      clearable
                      v-model="addUserForm3.FirstName"
                      placeholder="First Name*"
                    ></el-input>
                  </el-form-item>
                  <el-form-item prop="LastName">
                    <el-input
                      style="width: 100%"
                      clearable
                      v-model="addUserForm3.LastName"
                      placeholder="Last Name*"
                    ></el-input>
                  </el-form-item>

                  <el-form-item prop="Phone">
                    <el-input
                      style="width: 100%"
                      clearable
                      v-model="addUserForm3.Phone"
                      placeholder="Phone Number*"
                    ></el-input>
                  </el-form-item>
                  <el-form-item prop="Address">
                    <el-input
                      style="width: 100%"
                      clearable
                      @focus="geolocate()"
                      name="autocomplete"
                      id="autocompletepc3"
                      autocomplete="off"
                      v-model="addUserForm3.Address"
                    ></el-input>
                  </el-form-item>
                  <el-form-item prop="Email">
                    <el-input
                      style="width: 100%"
                      clearable
                      v-model="addUserForm3.Email"
                      placeholder="Email*"
                    ></el-input>
                  </el-form-item>
                </el-form>

                <el-button
                 :class="offsetwidth>1600?'mt_20':''"
                  @click="Submitform3(item.name)"
                  style="
                    width: 90%;
                    height: 13%;
                    background-color: rgb(34, 173, 56);
                    color: white;
                    border-radius: 10px;
                    font-size: 20px;
                  "
                  >Submit</el-button
                >
              </div> -->
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <img
        style="width: 100%"
        src="../assets/imgthree/PCNEW/我们的产品1.jpg"
        alt=""
      />
      <!-- :interval="5000" -->
      <div style="display: flex; width: 100%; justify-content: center">
        <img
          style="width: 100%"
          src="../assets/imgthree/PCNEW/两边的背景.jpg"
          alt=""
        />
        <img
          @click="jump('sp')"
          style="width: 100%"
          src="../assets/imgthree/PCNEW/内容1.jpg"
          alt=""
        />
        <img
          @click="jump('mac')"
          style="width: 100%"
          src="../assets/imgthree/PCNEW/内容2.jpg"
          alt=""
        />
        <img
          style="width: 100%"
          src="../assets/imgthree/PCNEW/两边的背景.jpg"
          alt=""
        />
      </div>
      <img style="width: 100%" src="../assets/imgthree/PCNEW/homework.jpg" alt="" />
      <div style="position: relative">
        <img
          style="width: 100%"
          src="../assets/imgthree/PCNEW/buttoncackground.jpg"
          alt=""
        />

        <img
          style="
            width: 23%;
            position: absolute;
            top: 60%;
            left: 50%;
            cursor: pointer;
            transform: translate(-50%, -50%);
          "
          @click="backtop()"
          src="../assets/imgthree/PCNEW/backtothetop.png"
          alt=""
        />
      </div>
      <img style="width: 100%" src="../assets/imgthree/PCNEW/底部.jpg" alt="" />
    </div>
    <div
      v-else
      style="
        width: 100%;
        height: 100%;
        border: 1px solid #0d131f;
        overflow: hidden;
        position: relative;
      "
    >
      <div style="width: 100%">
        <el-carousel
          :height="imgHeight"
          trigger="click"
          :interval="6000"
          arrow="always"
        >
          <el-carousel-item v-for="(item, index) in imagesh5" :key="index">
            <img
              ref="imgHeight"
              :src="item.idview"
              alt="Nodata"
              style="width: 100%"
              @load="imgLoad"
              @click="jump(item.name)"
            />
            <div>
              <div
                v-if="item.name == 'sp'"
                class="pchome"
                style="
                  border-style: solid;
                  border-color: transparent;
                  border-width: 5px;
                  position: absolute;
                  top: 62%;
                  right: 9%;
                  color: black;
                  width: 82%;
                  height: 34%;
                "
              >
                <div style="font-size: 30px; color: rgb(34, 173, 56)">
                  APPLY NOW
                </div>
                <el-form
                  ref="addUserForm1"
                  :model="addUserForm1"
                  :rules="addUserRule"
                  auto-complete="on"
                >
                  <el-form-item prop="FirstName">
                    <el-input
                      style="width: 100%"
                      clearable
                      v-model="addUserForm1.FirstName"
                      placeholder="First Name*"
                    ></el-input>
                  </el-form-item>
                  <el-form-item prop="LastName">
                    <el-input
                      style="width: 100%"
                      clearable
                      v-model="addUserForm1.LastName"
                      placeholder="Last Name*"
                    ></el-input>
                  </el-form-item>

                  <el-form-item prop="Phone">
                    <el-input
                      style="width: 100%"
                      clearable
                      v-model="addUserForm1.Phone"
                      placeholder="Phone Number*"
                    ></el-input>
                  </el-form-item>
                  <el-form-item prop="Address">
                    <el-input
                      style="width: 100%"
                      clearable
                      @focus="geolocate()"
                      name="autocomplete"
                      id="autocompleteh5"
                      autocomplete="off"
                      v-model="addUserForm1.Address"
                    ></el-input>
                  </el-form-item>
                  <el-form-item prop="Email">
                    <el-input
                      style="width: 100%"
                      clearable
                      v-model="addUserForm1.Email"
                      placeholder="Email*"
                    ></el-input>
                  </el-form-item>
                </el-form>

                <el-button
                  @click="Submitform1(item.name)"
                  style="
                    width: 90%;
                    height: 13%;
                    background-color: rgb(34, 173, 56);
                    color: white;
                    border-radius: 10px;
                    margin-top: 10px;
                    font-size: 20px;
                  "
                  >Submit</el-button
                >
              </div>
              <div
                v-if="item.name == 'mac'"
                class="pchome"
                style="
                  border-style: solid;
                  border-color: transparent;
                  border-width: 5px;
                  position: absolute;
                  top: 62%;
                  right: 9%;
                  color: black;
                  width: 82%;
                  height: 34%;
                "
              >
                <div style="font-size: 30px; color: rgb(34, 173, 56)">
                  APPLY NOW
                </div>
                <el-form
                  ref="addUserForm2"
                  :model="addUserForm2"
                  :rules="addUserRule"
                  auto-complete="on"
                >
                  <el-form-item prop="FirstName">
                    <el-input
                      style="width: 100%"
                      clearable
                      v-model="addUserForm2.FirstName"
                      placeholder="First Name*"
                    ></el-input>
                  </el-form-item>
                  <el-form-item prop="LastName">
                    <el-input
                      style="width: 100%"
                      clearable
                      v-model="addUserForm2.LastName"
                      placeholder="Last Name*"
                    ></el-input>
                  </el-form-item>

                  <el-form-item prop="Phone">
                    <el-input
                      style="width: 100%"
                      clearable
                      v-model="addUserForm2.Phone"
                      placeholder="Phone Number*"
                    ></el-input>
                  </el-form-item>
                  <el-form-item prop="Address">
                    <el-input
                      style="width: 100%"
                      clearable
                      @focus="geolocate()"
                      name="autocomplete"
                      id="autocompleteh52"
                      autocomplete="off"
                      v-model="addUserForm2.Address"
                    ></el-input>
                  </el-form-item>
                  <el-form-item prop="Email">
                    <el-input
                      style="width: 100%"
                      clearable
                      v-model="addUserForm2.Email"
                      placeholder="Email*"
                    ></el-input>
                  </el-form-item>
                </el-form>

                <el-button
                  @click="Submitform2(item.name)"
                  style="
                    width: 90%;
                    height: 13%;
                    background-color: rgb(34, 173, 56);
                    color: white;
                    border-radius: 10px;
                    margin-top: 10px;
                    font-size: 20px;
                  "
                  >Submit</el-button
                >
              </div>
              <!-- <div
                v-if="item.name == 'home'"
                class="pchome"
                style="
                  border-style: solid;
                  border-color: transparent;
                  border-width: 5px;
                  position: absolute;
                  top: 62%;
                  right: 9%;
                  color: black;
                  width: 82%;
                  height: 34%;
                "
              >
                <div style="font-size: 30px; color: rgb(34, 173, 56)">
                  APPLY NOW
                </div>
                <el-form
                  ref="addUserForm3"
                  :model="addUserForm3"
                  :rules="addUserRule"
                  auto-complete="on"
                >
                  <el-form-item prop="FirstName">
                    <el-input
                      style="width: 100%"
                      clearable
                      v-model="addUserForm3.FirstName"
                      placeholder="First Name*"
                    ></el-input>
                  </el-form-item>
                  <el-form-item prop="LastName">
                    <el-input
                      style="width: 100%"
                      clearable
                      v-model="addUserForm3.LastName"
                      placeholder="Last Name*"
                    ></el-input>
                  </el-form-item>

                  <el-form-item prop="Phone">
                    <el-input
                      style="width: 100%"
                      clearable
                      v-model="addUserForm3.Phone"
                      placeholder="Phone Number*"
                    ></el-input>
                  </el-form-item>
                  <el-form-item prop="Address">
                    <el-input
                      style="width: 100%"
                      clearable
                      @focus="geolocate()"
                      name="autocomplete"
                      id="autocompleteh53"
                      autocomplete="off"
                      v-model="addUserForm3.Address"
                    ></el-input>
                  </el-form-item>
                  <el-form-item prop="Email">
                    <el-input
                      style="width: 100%"
                      clearable
                      v-model="addUserForm3.Email"
                      placeholder="Email*"
                    ></el-input>
                  </el-form-item>
                </el-form>

                <el-button
                  @click="Submitform3(item.name)"
                  style="
                    width: 90%;
                    height: 13%;
                    background-color: rgb(34, 173, 56);
                    color: white;
                    border-radius: 10px;
                    margin-top: 10px;
                    font-size: 20px;
                  "
                  >Submit</el-button
                >
              </div> -->
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
        <!-- <img
        style="width: 100%;"
        src="../assets/imgthree/H5NEW/3b3f803f0e37e2a69e30ddcf3c078ba.png"
        alt=""
      /> -->
      <img
        style="width: 100%"
        @click="jump('sp')"
        src="../assets/imgthree/H5NEW/sp11.jpg"
        alt=""
      />
      <img
        @click="jump('mac')"
        style="width: 100%"
        src="../assets/imgthree/H5NEW/2-2.jpg"
        alt=""
      />
      <!-- <img
        @click="jump('home')"
        style="width: 100%"
        src="../assets/imgthree/H5/homeallpro.jpg"
        alt=""
      /> -->
      <img style="width: 100%" src="../assets/imgthree/H5NEW/homework.png" alt="" />
      <div style="position: relative">
        <img
          style="width: 100%"
          src="../assets/imgthree/H5NEW/buttonbackground.png"
          alt=""
        />

        <img
          style="
            width: 65%;
            position: absolute;
            top: 55%;
            left: 50%;
            cursor: pointer;
            transform: translate(-50%, -50%);
          "
          @click="backtop()"
          src="../assets/imgthree/H5NEW/首页按钮.png"
          alt=""
        />
      </div>
      <img style="width: 100%" src="../assets/imgthree/H5NEW/底部手机版.jpg" alt="" />
    </div>
    <GMaps
      v-show="false"
      class="Gmaps"
      @fun="getinfo"
      :offsetwidth="offsetwidth"
      ref="child"
      :map-data="mapData"
    />
    <!-- <GMaps2
      v-show="false"
      class="Gmaps"
      @fun="getinfo2"
       :offsetwidth="offsetwidth"
      ref="child2"
      :map-data="mapData"
    /> -->
  </div>
</template>

<script>
import GMaps from "@/components/map/Gmaps";
// import GMaps2 from "@/components/map2/Gmaps";
import { get, post } from "../assets/api/api";
export default {
  components: { GMaps },
  props: {
    offsetwidth: {
      type: Number,
    },
  },
  data() {
    const checkphone = (rule, value, callback) => {
      let reg = /^[0][3,4][0-9]{8}$/;
      if (!reg.test(value)) {
        return callback(new Error(`Please provide a valid phone number. `));
      }
      callback();
    };
    const regemail = (rule, value, callback) => {
      let reg =
        /^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$/;
      if (!reg.test(value) && value) {
        return callback(new Error("The email format is not correct."));
      }
      callback();
    };
    const notNull = (rule, value, callback) => {
      // console.log(value)
      if (!value) {
        return callback(new Error(`${rule.field} is required `));
      }
      callback();
    };
    const checkpostcode = (rule, value, callback) => {
      let reg = /^3[0-9]{3}$/;
      if (!reg.test(value)) {
        return callback(new Error(`${rule.field} is required `));
      }

      callback();
    };
    return {
      imagesh5: [
        {
          name: "sp",
          idview: require("../assets/imgthree/H5NEW/1-1首页手机版.jpg"),
        },
        { name: "mac", idview: require("../assets/imgthree/H5NEW/1-2首页手机版.jpg") },
        // { name: "home", idview: require("../assets/imgthree/H5/hometop3.jpg") },
      ],
      formdata: {},
      imgHeight: "",
      // 产品图片，注意图片命名
      images: [
        {
          name: "sp",
          idview: require("../assets/imgthree/PCNEW/SP.jpg"),
        },
        { name: "mac", idview: require("../assets/imgthree/PCNEW/MACTOP.jpg") },
        // { name: "home", idview: require("../assets/imgthree/PC/hometop3.png") },
      ],
      checked: false,
      mapData: {
        latitude: 33.242,
        longitude: 124.12,
      },
      mapData2: {
        latitude: 33.242,
        longitude: 124.12,
      },
      selectList: [
        { tag: "Yes", value: "Yes" },
        { tag: "No", value: "No" },
      ],
      signRules2: {
        FirstName: [
          {
            validator: notNull,
            trigger: ["change", "blur"],
          },
        ],
        LastName: [
          {
            validator: notNull,
            trigger: ["change", "blur"],
          },
        ],
        Address: [
          {
            validator: notNull,
            trigger: ["change", "blur"],
          },
        ],
        Phone: [
          {
            validator: notNull,
            trigger: ["change", "blur"],
          },
          {
            validator: checkphone,
            len: 10,
            trigger: ["change", "blur"],
          },
        ],
        Email: [
          {
            validator: notNull,
            trigger: ["change", "blur"],
          },
          {
            validator: regemail,
            message: "Please enter a valid email address.",
            trigger: ["change", "blur"],
          },
        ],
        Commeriacl: [
          {
            validator: notNull,
            trigger: ["change", "blur"],
          },
        ],
      },
      signForm2: {
        FirstName: "",
        LastName: "",
        Address: "",
        Phone: "",
        Email: "",
        Commeriacl: "",
      },
      addUserForm: {
        FirstName: "",
        LastName: "",
        Address: "",
        Phone: "",
        Email: "",
      },
      addUserForm1: {
        FirstName: "",
        LastName: "",
        Address: "",
        Phone: "",
        Email: "",
      },
      addUserForm3: {
        FirstName: "",
        LastName: "",
        Address: "",
        Phone: "",
        Email: "",
      },
      addUserForm2: {
        FirstName: "",
        LastName: "",
        Address: "",
        Phone: "",
        Email: "",
      },
      addUserRule: {
        FirstName: [
          {
            validator: notNull,
            trigger: ["change", "blur"],
          },
        ],
        LastName: [
          {
            validator: notNull,
            trigger: ["change", "blur"],
          },
        ],
        Address: [
          {
            validator: notNull,
            trigger: ["change", "blur"],
          },
        ],
        Phone: [
          {
            validator: notNull,
            trigger: ["change", "blur"],
          },
          {
            validator: checkphone,
            len: 10,
            trigger: ["change", "blur"],
          },
        ],
        Email: [
          {
            validator: notNull,
            trigger: ["change", "blur"],
          },
          {
            validator: regemail,
            message: "Please enter a valid email address.",
            trigger: ["change", "blur"],
          },
        ],
        Commeriacl: [
          {
            validator: notNull,
            trigger: ["change", "blur"],
          },
        ],
      },
    };
  },
  mounted() {
    this.imgLoad();
    window.onresize = () => {
      this.imgLoad();
    };

    // window.fbq("track", "ViewContent", {
    //   content_type: "index",
    //   content_ids: ["10001"],
    //   content_name: "index",
    //   content_category: "index",
    // });
  },
  destroyed() {
    window.onresize = null;
  },
  imgLoad() {
    this.$nextTick(() => {
      this.imgHeight = this.$refs.imgHeight["0"].height + "px";
    });
  },

  methods: {
    imgLoad() {
      this.$nextTick(() => {
        this.imgHeight = this.$refs.imgHeight["0"].height + "px";
      });
    },

    jump(tag) {
      if (tag == "sp") {
        this.$router.push({
          path: "/SolarPanelSystem",
        });
      } else if (tag == "home") {
        this.$router.push({
          path: "/HomeEssential",
        });
      } else if (tag == "mac") {
        this.$router.push({
          path: "/MAC",
        });
      }
    },
    Submitform1() {
      this.$refs.addUserForm1[0].validate((valid) => {
        if (valid) {
          this.formdata = {
            To_Store_ID: 117,
            FirstName: this.addUserForm1.FirstName,
            LastName: this.addUserForm1.LastName,
            Phone: this.addUserForm1.Phone,
            Emailaddress: this.addUserForm1.Email,
            PostCode: this.addUserForm1.Postcode,
            Address: this.addUserForm1.Address,
            Unit_Number: "",
            Street_Number: this.addUserForm1.Street_number,
            Street_Name: this.addUserForm1.streetName,
            Suburb: this.addUserForm1.Suburb,
            City: this.addUserForm1.City,
            Language: "",
            ToSalesID: 20183,
            Pro_SH: 0,
            Pro_IHD: 0,
            Pro_DS: 0,
            Pro_CS: 0,
            Pro_LED: 0,
            Pro_FS: 0,
            Pro_AC: 0,
            Pro_SP: 1,
            Pro_HP: 0,
            Pro_MAC: 0,
          };
          // console.log(this.formdata)
          post("/api/api/QR_CustomerOrders/AddOrder", this.formdata).then(
            (res) => {
              if (res.data.code == 201) {
                this.addUserForm1 = {};
                this.$message.success("Successful.");
              } else {
                this.$message({
                  type: "error",
                  message: res.data.msg,
                  duration: 2500,
                });
              }
            }
          );
        }
      });
    },
    Submitform2() {
      this.$refs.addUserForm2[0].validate((valid) => {
        if (valid) {
          this.formdata = {
            To_Store_ID: 117,
            FirstName: this.addUserForm2.FirstName,
            LastName: this.addUserForm2.LastName,
            Phone: this.addUserForm2.Phone,
            Emailaddress: this.addUserForm2.Email,
            PostCode: this.addUserForm2.Postcode,
            Address: this.addUserForm2.Address,
            Unit_Number: "",
            Street_Number: this.addUserForm2.Street_number,
            Street_Name: this.addUserForm2.streetName,
            Suburb: this.addUserForm2.Suburb,
            City: this.addUserForm2.City,
            Language: "",
            ToSalesID: 20183,
            Pro_SH: 0,
            Pro_IHD: 0,
            Pro_DS: 0,
            Pro_CS: 0,
            Pro_LED: 0,
            Pro_FS: 0,
            Pro_AC: 0,
            Pro_SP: 0,
            Pro_HP: 0,
            Pro_MAC: 1,
          };
          post("/api/api/QR_CustomerOrders/AddOrder", this.formdata).then(
            (res) => {
              if (res.data.code == 201) {
                this.addUserForm2 = {};
                this.$message.success("Successful.");
              } else {
                this.$message({
                  type: "error",
                  message: res.data.msg,
                  duration: 2500,
                });
              }
            }
          );
        }
      });
    },
    Submitform3() {
      this.$refs.addUserForm3[0].validate((valid) => {
        if (valid) {
          this.formdata = {
            To_Store_ID: 117,
            FirstName: this.addUserForm3.FirstName,
            LastName: this.addUserForm3.LastName,
            Phone: this.addUserForm3.Phone,
            Emailaddress: this.addUserForm3.Email,
            PostCode: this.addUserForm3.Postcode,
            Address: this.addUserForm3.Address,
            Unit_Number: "",
            Street_Number: this.addUserForm3.Street_number,
            Street_Name: this.addUserForm3.streetName,
            Suburb: this.addUserForm3.Suburb,
            City: this.addUserForm3.City,
            Language: "",
            ToSalesID: 20183,
            Pro_SH: 1,
            Pro_IHD: 1,
            Pro_DS: 1,
            Pro_CS: 1,
            Pro_LED: 0,
            Pro_FS: 1,
            Pro_AC: 0,
            Pro_SP: 0,
            Pro_HP: 0,
            Pro_MAC: 0,
          };
          post("/api/api/QR_CustomerOrders/AddOrder", this.formdata).then(
            (res) => {
              if (res.data.code == 201) {
                this.addUserForm3 = {};
                this.$message.success("Successful.");
              } else {
                this.$message({
                  type: "error",
                  message: res.data.msg,
                  duration: 2500,
                });
              }
            }
          );
        }
      });
    },
backtop(){
document.documentElement.scrollTop = 0;
},
    // Submitform(tag) {
    //   console.log(tag)
    //   this.$refs.addUserForm[0].validate((valid) => {
    //     if (valid) {
    //       if (tag == "sp") {
    //         this.formdata = {
    //           To_Store_ID: 117,
    //           FirstName: this.addUserForm.FirstName,
    //           LastName: this.addUserForm.LastName,
    //           Phone: this.addUserForm.Phone,
    //           Emailaddress: this.addUserForm.Email,
    //           PostCode: this.addUserForm.Postcode,
    //           Address: this.addUserForm.Address,
    //           Unit_Number: "",
    //           Street_Number: this.addUserForm.Street_number,
    //           Street_Name: this.addUserForm.streetName,
    //           Suburb: this.addUserForm.Suburb,
    //           City: this.addUserForm.City,
    //           Language: "",
    //           ToSalesID: 20183,
    //           Pro_SH: 0,
    //           Pro_IHD: 0,
    //           Pro_DS: 0,
    //           Pro_CS: 0,
    //           Pro_LED: 0,
    //           Pro_FS: 0,
    //           Pro_AC: 0,
    //           Pro_SP: 1,
    //           Pro_HP: 0,
    //           Pro_MAC: 0,
    //         };
    //       } else if (tag == "mac") {
    //         this.formdata = {
    //           To_Store_ID: 117,
    //           FirstName: this.addUserForm.FirstName,
    //           LastName: this.addUserForm.LastName,
    //           Phone: this.addUserForm.Phone,
    //           Emailaddress: this.addUserForm.Email,
    //           PostCode: this.addUserForm.Postcode,
    //           Address: this.addUserForm.Address,
    //           Unit_Number: "",
    //           Street_Number: this.addUserForm.Street_number,
    //           Street_Name: this.addUserForm.streetName,
    //           Suburb: this.addUserForm.Suburb,
    //           City: this.addUserForm.City,
    //           Language: "",
    //           ToSalesID: 20183,
    //           Pro_SH: 0,
    //           Pro_IHD: 0,
    //           Pro_DS: 0,
    //           Pro_CS: 0,
    //           Pro_LED: 0,
    //           Pro_FS: 0,
    //           Pro_AC: 0,
    //           Pro_SP: 0,
    //           Pro_HP: 0,
    //           Pro_MAC: 1,
    //         };
    //       } else if (tag == "home") {
    //         this.formdata = {
    //           To_Store_ID: 117,
    //           FirstName: this.addUserForm.FirstName,
    //           LastName: this.addUserForm.LastName,
    //           Phone: this.addUserForm.Phone,
    //           Emailaddress: this.addUserForm.Email,
    //           PostCode: this.addUserForm.Postcode,
    //           Address: this.addUserForm.Address,
    //           Unit_Number: "",
    //           Street_Number: this.addUserForm.Street_number,
    //           Street_Name: this.addUserForm.streetName,
    //           Suburb: this.addUserForm.Suburb,
    //           City: this.addUserForm.City,
    //           Language: "",
    //           ToSalesID: 20183,
    //           Pro_SH: 1,
    //           Pro_IHD: 1,
    //           Pro_DS: 1,
    //           Pro_CS: 1,
    //           Pro_LED: 0,
    //           Pro_FS: 1,
    //           Pro_AC: 0,
    //           Pro_SP: 0,
    //           Pro_HP: 0,
    //           Pro_MAC: 0,
    //         };
    //       }
    //       console.log(this.formdata);
    // post("/api/api/QR_CustomerOrders/AddOrder", this.formdata).then(
    //   (res) => {
    //     if (res.data.code == 201) {
    //       this.formdata = {};
    //       this.$message.success("Successful.");
    //     }
    //   }
    //       );
    //     }
    //   });
    // },
    geolocate() {
      this.$refs.child.geolocate;
    },
    initAutocomplete() {
      this.$refs.child.initAutocomplete;
    },
    fillInAddress() {
      this.$refs.child.fillInAddress;
    },
    //获取组件中的谷歌地图信息
    getinfo(data) {
      console.log(data);
      if (data.tag == "placepc" || data.tag == "placeh5") {
        this.addUserForm1.Address = data.address;
        this.addUserForm1.Suburb = data.locality;
        this.addUserForm1.Postcode = data.postal_code;
        this.addUserForm1.streetName = data.route;
        this.addUserForm1.Street_number = data.street_number;
        this.addUserForm1.City = data.administrative_area_level_2;
        if (this.addUserForm1.streetName === "long_name") {
          this.addUserForm1.streetName = "";
        }
        if (this.addUserForm1.Suburb === "long_name") {
          this.addUserForm1.Suburb = "";
        }
        if (this.addUserForm1.Street_number === "short_name") {
          this.addUserForm1.Street_number = "";
        }
        if (this.addUserForm1.Postcode === "short_name") {
          this.addUserForm1.Postcode = "";
        }
        if (this.addUserForm1.City === "short_name") {
          this.addUserForm1.City = "";
        }
      } else if (data.tag == "placepc2" || data.tag == "placeh52") {
        this.addUserForm2.Address = data.address;
        this.addUserForm2.Suburb = data.locality;
        this.addUserForm2.Postcode = data.postal_code;
        this.addUserForm2.streetName = data.route;
        this.addUserForm2.Street_number = data.street_number;
        this.addUserForm2.City = data.administrative_area_level_2;
        if (this.addUserForm2.streetName === "long_name") {
          this.addUserForm2.streetName = "";
        }
        if (this.addUserForm2.Suburb === "long_name") {
          this.addUserForm2.Suburb = "";
        }
        if (this.addUserForm2.Street_number === "short_name") {
          this.addUserForm2.Street_number = "";
        }
        if (this.addUserForm2.Postcode === "short_name") {
          this.addUserForm2.Postcode = "";
        }
        if (this.addUserForm2.City === "short_name") {
          this.addUserForm2.City = "";
        }
      } else if (data.tag == "placepc3" || data.tag == "placeh53") {
        this.addUserForm3.Address = data.address;
        this.addUserForm3.Suburb = data.locality;
        this.addUserForm3.Postcode = data.postal_code;
        this.addUserForm3.streetName = data.route;
        this.addUserForm3.Street_number = data.street_number;
        this.addUserForm3.City = data.administrative_area_level_2;
        if (this.addUserForm3.streetName === "long_name") {
          this.addUserForm3.streetName = "";
        }
        if (this.addUserForm3.Suburb === "long_name") {
          this.addUserForm3.Suburb = "";
        }
        if (this.addUserForm3.Street_number === "short_name") {
          this.addUserForm3.Street_number = "";
        }
        if (this.addUserForm3.Postcode === "short_name") {
          this.addUserForm3.Postcode = "";
        }
        if (this.addUserForm3.City === "short_name") {
          this.addUserForm3.City = "";
        }
      }
      // if (data.tag == "top") {
      //   this.signForm2.Address = data.address;
      // } else if (data.tag == "bottom") {
      //   this.signForm.Address = data.address;
      // }
      // console.log(this.signForm.Address);
      // console.log(this.signForm2.Address);
      // console.log(data);
    },

    Submit(key) {
      console.log(key);
      this.key = key;
      var PageId = document.querySelector("#pages" + key);
      // console.log(PageId.offsetTop,PageId)
      //  top: parseInt(PageId.offsetTop - 100),
      if (key == 1) {
        window.scrollTo({
          top: 130,
          behavior: "smooth",
        });
        // this.showmenu = false;
      } else {
        window.scrollTo({
          top: PageId.offsetTop,
          behavior: "smooth",
        });
        // this.showmenu = false;
      }
    },
    goinfo() {
      // window.sessionStorage.setItem("activeIndex", "/CustomerDetails");

      this.$emit("fun", "/CustomerDetails");
    },
  },
};
</script>

<style  scoped lang="scss">
//  .el-carousel__item h3 {
//     color: #475669;
//     font-size: 18px;
//     opacity: 0.75;
//     line-height: 300px;
//     margin: 0;
//   }

//   .el-carousel__item:nth-child(2n) {
//     background-color: #99a9bf;
//   }

//   .el-carousel__item:nth-child(2n+1) {
//     background-color: #d3dce6;
//   }
::v-deep .el-carousel__arrow--left {
  border: none;
  outline: 0;
  padding: 0;
  margin: 0;
  height: 56px;
  width: 56px;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  color: #fff;
  position: absolute;
  top: 50%;
  z-index: 10;
  transform: translateY(-50%);
  text-align: center;
  font-size: 30px;
  // top: 70px;
  // left: -13px;
  // font-size: 15px;
  // color: #02E9FF;
  // display: none;
}
// ::v-deep.el-carousel__arrow {
//     border: none;
//     outline: 0;
//     padding: 0;
//     margin: 0;
//     height: 56px;
//     width: 56px;
//     cursor: pointer;
//     transition: .3s;
//     border-radius: 50%;
//     background-color: rgba(255,255,255,.5);
//     color: #FFF;
//     position: absolute;
//     top: 50%;
//     z-index: 10;
//     transform: translateY(-50%);
//     text-align: center;
//     font-size: 30px;
// }
//右箭头
::v-deep .el-carousel__arrow--right {
  border: none;
  outline: 0;
  padding: 0;
  margin: 0;
  height: 56px;
  width: 56px;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  color: #fff;
  position: absolute;
  top: 50%;
  z-index: 10;
  transform: translateY(-50%);
  text-align: center;
  font-size: 30px;
  // display: none;
  // top: 70px;
  // right: -13px;
  // font-size: 15px;
  // color: #02E9FF;
}
// .el-carousel__item h3 {
//     color: #475669;
//     font-size: 14px;
//     opacity: 0.75;
//     line-height: 150px;
//     margin: 0;
//   }

//   .el-carousel__item:nth-child(2n) {
//     background-color: #99a9bf;
//   }

//   .el-carousel__item:nth-child(2n + 1) {
//     background-color: #d3dce6;
//   }
// ::v-deep .el-carousel__indicator--horizontal {
//        position: relative;
//        bottom:0;
//         transform: translateX(-50%);
//         // left: auto;
//         // right: 10px;
//         // bottom: 10px;
//         // text-align: right;

// }
// ::v-deep.el-carousel__indicators { // 指示灯位置
//   left: unset;
//   transform: unset;
//   right: 2%;
// }
::v-deep .el-carousel__button {
  left: auto;
  right: 10px;
  bottom: 10px;
  text-align: right;
  // top: 4.5%;
  // right: 22%;
  display: block;
  opacity: 0.48;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: none;
  outline: 0;
  padding: 0;
  margin: 5px;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
}
// .el-carousel__item h3 {
//   color: #475669;
//   font-size: 14px;
//   opacity: 0.75;
//   line-height: 150px;
//   margin: 0;
// }

// .el-carousel__item:nth-child(2n) {
//    background-color: #99a9bf;
// }

// .el-carousel__item:nth-child(2n+1) {
//    background-color: #d3dce6;
// }
::v-deep .pchome.el-checkbox__label {
  display: inline-block;
  padding-left: 10px;
  line-height: 19px;
  font-size: 15px;
}
// .el-carousel__item.is-animation{
//   transition:transform 1s ease-in-out;
// }
::v-deep .h5home .el-checkbox__label {
  display: inline-block;
  padding-left: 10px;
  line-height: 19px;
  font-weight: 700;
  font-size: 17px;
}
.el-form-item {
  margin-bottom: 15px;
}
::v-deep.el-select .el-input__inner {
  border-radius: 20px;
}
.mt_20 {
  margin-top: 20px;
}
.mb_20 {
  margin-bottom: 20px;
}
::v-deep.el-input--suffix .el-input__inner {
  padding-right: 30px;
  border-radius: 20px;
}
</style>
