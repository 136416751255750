<template>

  <div v-title data-title="GREEN SPARK">
    <!-- style=" border-style: solid; border-width: 5px; border-color: transparent;" -->
    <div v-if="offsetwidth > 850" >
      <img style="width: 100%" src="../assets/imgthree/PC/TCtop.png" alt="" />
        <img style="width: 100%" src="../assets/imgthree/PC/tc.jpg" alt="" />
         <div style="position: relative">
        <img style="width: 100%" src="../assets/imgthree/PC/join-now背景.jpg" alt="" />

        <img
          style="
            width: 25%;
            position: absolute;
            top: 60%;
            left: 50%;
            cursor: pointer;
            transform: translate(-50%, -50%);
          "
          @click="goinfo"
          src="../assets/imgthree/PC/join now.png"
          alt=""
        />
        
      </div>
        <img
        style="width: 100%"
        src="../assets/imgthree/PCNEW/底部.jpg"
        alt=""
      />
    </div>
    <div v-else style="margin-bottom: 20px">
       <img
        style="width: 100%"
        src="../assets/imgthree/H5/4-Terms-and-Conditions.jpg"
        alt=""
      />
      <div
       class="flex flex_around text_left" 
        style="
          width: 100%;
          min-width: 240px;
          max-width: 800px;
          margin-top: 10px;
          backround: #eee;
          padding: 10px 15px;
           text-align: left;
        "
      >
       <span class="text_bold font_16">  TERMS AND CONDITIONS FOR ASTEP TO GREEN FACEBOOK/INSTAGRAM ADS</span>

        <div class=" font_15">
          <div>
            <p>
             These terms and conditions ("Terms") govern your use of and interaction with the advertisements ("Ads") displayed by Green Spark on Facebook. By engaging with our Ads or accessing our services, you agree to comply with and be bound by these Terms. Please read them carefully.
            </p>

            <ol>
              <li style="margin-top: 10px">
                Advertisements: Green Spark may display Ads on Facebook/Instagram to promote its products, services, or events. The content, format, and duration of the Ads are subject to change without notice.
              </li>
              <li style="margin-top: 10px">
                Advertiser's Responsibility: Green Spark strives to provide accurate and up- to-date information in its Ads. However, we do not guarantee the completeness, accuracy, or reliability of the information. It is your responsibility to verify the information presented in the Ads before making any decisions or taking any actions based on them.
              </li>
              <li style="margin-top: 10px">
                Targeted Audience: The Ads may be targeted to specific audiences based on various factors, such as demographics, interests, or location. The targeting criteria are determined solely by Green Spark and are subject to change at any time.
              </li>
              <li style="margin-top: 10px">
               Third-Party Websites: The Ads may contain links to third-party websites or resources. Green Saprk does not endorse or assume any responsibility for the content, products, or services provided by such third parties. You acknowledge that your interactions with third-party websites or resources are solely at your own risk.
              </li>
              <li style="margin-top: 10px">
                Intellectual Property: The Ads, including but not limited to text, images, logos, trademarks, and videos, are the intellectual property of Green Spark or its licensors. You may not modify, reproduce, distribute, or create derivative works based on the Ads without obtaining explicit written permission from Green Spark Victoria.
              </li>
              <li style="margin-top: 10px">
                Data Collection and Privacy: By interacting with our Ads, you acknowledge and agree that Green Spark may collect and process your personal data in accordance with our Privacy Policy. Please refer to our Privacy Policy for detailed information on how we handle and protect your personal information.
              </li>
              <li style="margin-top: 10px">
                Limitation of Liability: To the extent permitted by applicable law, Green Spark shall not be liable for any direct, indirect, incidental, consequential, or exemplary damages arising from or related to the Ads, including but not limited to errors, omissions, or inaccuracies in the content.
              </li>
              <li style="margin-top: 10px">
                Modifications: Green Spark reserves the right to modify or discontinue the Ads at any time without prior notice. We may also update these Terms periodically, and any changes will be effective immediately upon posting. It is your responsibility to review these Terms regularly to stay informed of any updates.
              </li>
              <li style="margin-top: 10px">
                Governing Law: These Terms shall be governed by and construed in accordance with the laws of the jurisdiction where Green Spark operates, without regard to its conflict of law provisions.
              </li>
              <li style="margin-top: 10px">
                Contact: If you have any questions or concerns about these Terms or our Ads, please contact us at (03) 8528 1831.
              </li>
            </ol>
          </div>
        </div>
      </div>
        <div style="position: relative">
        <img style="width: 100%" src="../assets/imgthree/H5/背景图.jpg" alt="" />

        <img
          style="
           width: 65%;
            position: absolute;
            top: 70%;
            left: 50%;
            cursor: pointer;
            transform: translate(-50%, -50%);
          "
          @click="goinfo"
          src="../assets/imgthree/H5/join now.png"
          alt=""
        />
        
      </div>
        <img
        style="width: 100%"
        src="../assets/imgthree/H5NEW/底部手机版.jpg"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    offsetwidth: {
      type: Number,
    },
  },
  mounted() {
    window.fbq("track", "ViewContent", {
      content_type: "Contact Us",
      content_ids: ["10005"],
      content_name: "Contact Us",
      content_category: "Contact Us",
    });
  },
  methods: {
    goinfo() {
      // window.sessionStorage.setItem("activeIndex", "/CustomerDetails");

      this.$emit("fun", "/CustomerDetails");
    },
  },
};
</script>

<style>
.text_bold {
  font-weight: 700;
}
</style>
