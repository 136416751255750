<template>
  <div v-title data-title="GREEN SPARK">
    <div
      v-if="offsetwidth > 850"
      style="width: 100%; height: 100%; overflow: hidden; position: relative"
    >
    
        <img
        style="width: 100%"
        src="../assets/imgthree/PCNEW/contactus.jpg"
        alt=""
      />
    
       <div style="position: relative">
        <img style="width: 100%" src="../assets/imgthree/PC/join-now背景.jpg" alt="" />

        <img
          style="
            width: 25%;
            position: absolute;
            top: 60%;
            left: 50%;
            cursor: pointer;
            transform: translate(-50%, -50%);
          "
          @click="goinfoPC"
          src="../assets/imgthree/PC/join now.png"
          alt=""
        />
      </div>
          <img
        style="width: 100%"
        src="../assets/imgthree/PCNEW/底部.jpg"
        alt=""
      />
    </div>
    <div
      v-else
      style="
        width: 100%;
      "
    >
   
        <img style="width: 100%" src="../assets/imgthree/H5NEW/contactus.jpg" alt="" />
          <div style="position: relative">
        <img style="width: 100%" src="../assets/imgthree/H5/背景图.jpg" alt="" />

        <img
          style="
            width: 65%;
            position: absolute;
            top: 70%;
            left: 50%;
            cursor: pointer;
            transform: translate(-50%, -50%);
          "
          @click="goinfoH5"
          src="../assets/imgthree/H5/join now.png"
          alt=""
        />
        
      </div>
      <img style="width: 100%" src="../assets/imgthree/H5NEW/底部手机版.jpg" alt="" />
    </div>
    
    <!-- <GMaps
      v-show="false"
      class="Gmaps"
      @fun="getinfo"
      :offsetwidth="offsetwidth"
      ref="child"
      :map-data="mapData"
    /> -->
    <!-- <GMaps2
      v-show="false"
      class="Gmaps"
      @fun="getinfo2"
       :offsetwidth="offsetwidth"
      ref="child2"
      :map-data="mapData"
    /> -->
  </div>
</template>

<script>
// import GMaps from "@/components/map/Gmaps";
// import GMaps2 from "@/components/map2/Gmaps";
export default {
  // components: { GMaps },
  props: {
    offsetwidth: {
      type: Number,
    },
  },
  data() {
    const checkphone = (rule, value, callback) => {
      let reg = /^[0][3,4][0-9]{8}$/;
      if (!reg.test(value)) {
        return callback(new Error(`Please provide a valid phone number. `));
      }
      callback();
    };
    const regemail = (rule, value, callback) => {
      let reg =
        /^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$/;
      if (!reg.test(value) && value) {
        return callback(new Error("The email format is not correct."));
      }
      callback();
    };
    const notNull = (rule, value, callback) => {
      // console.log(value)
      if (!value) {
        return callback(new Error(`${rule.field} is required `));
      }
      callback();
    };
    const checkpostcode = (rule, value, callback) => {
      let reg = /^3[0-9]{3}$/;
      if (!reg.test(value)) {
        return callback(new Error(`${rule.field} is required `));
      }

      callback();
    };
    return {
      checked: false,
      mapData: {
        latitude: 33.242,
        longitude: 124.12,
      },
      mapData2: {
        latitude: 33.242,
        longitude: 124.12,
      },
      selectList: [
        { tag: "Yes", value: "Yes" },
        { tag: "No", value: "No" },
      ],
      signRules2: {
        FirstName: [
          {
            validator: notNull,
            trigger: ["change", "blur"],
          },
        ],
        LastName: [
          {
            validator: notNull,
            trigger: ["change", "blur"],
          },
        ],
        Address: [
          {
            validator: notNull,
            trigger: ["change", "blur"],
          },
        ],
        Phone: [
          {
            validator: notNull,
            trigger: ["change", "blur"],
          },
          {
            validator: checkphone,
            len: 10,
            trigger: ["change", "blur"],
          },
        ],
        Email: [
          {
            validator: notNull,
            trigger: ["change", "blur"],
          },
          {
            validator: regemail,
            message: "Please enter a valid email address.",
            trigger: ["change", "blur"],
          },
        ],
        Commeriacl: [
          {
            validator: notNull,
            trigger: ["change", "blur"],
          },
        ],
      },
      signForm2: {
        FirstName: "",
        LastName: "",
        Address: "",
        Phone: "",
        Email: "",
        Commeriacl: "",
      },
      signRules: {
        FirstName: [
          {
            validator: notNull,
            trigger: ["change", "blur"],
          },
        ],
        LastName: [
          {
            validator: notNull,
            trigger: ["change", "blur"],
          },
        ],
        Address: [
          {
            validator: notNull,
            trigger: ["change", "blur"],
          },
        ],
        Phone: [
          {
            validator: notNull,
            trigger: ["change", "blur"],
          },
          {
            validator: checkphone,
            len: 10,
            trigger: ["change", "blur"],
          },
        ],
        Email: [
          {
            validator: notNull,
            trigger: ["change", "blur"],
          },
          {
            validator: regemail,
            message: "Please enter a valid email address.",
            trigger: ["change", "blur"],
          },
        ],
        Commeriacl: [
          {
            validator: notNull,
            trigger: ["change", "blur"],
          },
        ],
      },
      signForm: {
        FirstName: "",
        LastName: "",
        Address: "",
        Phone: "",
        Email: "",
        Commeriacl: "",
      },
    };
  },
  mounted() {
    // window.fbq("track", "ViewContent", {
    //   content_type: "index",
    //   content_ids: ["10001"],
    //   content_name: "index",
    //   content_category: "index",
    // });
  },
  methods: {
    geolocate() {
      this.$refs.child.geolocate;
    },
    initAutocomplete() {
      this.$refs.child.initAutocomplete;
    },
    fillInAddress() {
      this.$refs.child.fillInAddress;
    },
    //获取组件中的谷歌地图信息
    getinfo(data) {
      console.log(data);
      if (data.tag == "top") {
        this.signForm2.Address = data.address;
      } else if (data.tag == "bottom") {
        this.signForm.Address = data.address;
      }
      console.log(this.signForm.Address);
      console.log(this.signForm2.Address);
      // console.log(data);
      // this.signForm.Suburb = data.locality;
      // this.signForm.Postcode = data.postal_code;
      // this.signForm.streetName = data.route;
      // this.signForm.Street_number = data.street_number;
      // this.signForm.City = data.administrative_area_level_2;
      // if (this.signForm.streetName === "long_name") {
      //   this.signForm.streetName = "";
      // }
      // if (this.signForm.Suburb === "long_name") {
      //   this.signForm.Suburb = "";
      // }
      // if (this.signForm.Street_number === "short_name") {
      //   this.signForm.Street_number = "";
      // }
      // if (this.signForm.Postcode === "short_name") {
      //   this.signForm.Postcode = "";
      // }
      // if (this.signForm.City === "short_name") {
      //   this.signForm.City = "";
      // }
    },

    // Submit(key) {
    //   console.log(key);
    //   this.key = key;
    //   var PageId = document.querySelector("#pages" + key);
    //   // console.log(PageId.offsetTop,PageId)
    //   //  top: parseInt(PageId.offsetTop - 100),
    //   if (key == 1) {
    //     window.scrollTo({
    //       top: 130,
    //       behavior: "smooth",
    //     });
    //     // this.showmenu = false;
    //   } else {
    //     window.scrollTo({
    //       top: PageId.offsetTop,
    //       behavior: "smooth",
    //     });
    //     // this.showmenu = false;
    //   }
    // },
    goinfoPC() {
       this.$emit("fun", "/CustomerDetails");
      // this.$router.push({name:'CustomerDetails',params:{pro:"PCMAC"}});
      // window.sessionStorage.setItem("activeIndex", "/CustomerDetails");
      // this.$emit("fun", "/CustomerDetails");
    },
     goinfoH5() {
        this.$emit("fun", "/CustomerDetails");
      // this.$router.push({name:'CustomerDetails',params:{pro:"H5MAC"}});
      // window.sessionStorage.setItem("activeIndex", "/CustomerDetails");
      // this.$emit("fun", "/CustomerDetails");
    },
  },
};
</script>

<style  scoped lang="scss">
//  .el-carousel__item h3 {
//     color: #475669;
//     font-size: 18px;
//     opacity: 0.75;
//     line-height: 300px;
//     margin: 0;
//   }

//   .el-carousel__item:nth-child(2n) {
//     background-color: #99a9bf;
//   }

//   .el-carousel__item:nth-child(2n+1) {
//     background-color: #d3dce6;
//   }
::v-deep .el-carousel__arrow--left {
  // top: 70px;
  // left: -13px;
  // font-size: 15px;
  // color: #02E9FF;
  display: none;
}

//右箭头
::v-deep .el-carousel__arrow--right {
  display: none;
  // top: 70px;
  // right: -13px;
  // font-size: 15px;
  // color: #02E9FF;
}
// .el-carousel__item h3 {
//     color: #475669;
//     font-size: 14px;
//     opacity: 0.75;
//     line-height: 150px;
//     margin: 0;
//   }

//   .el-carousel__item:nth-child(2n) {
//     background-color: #99a9bf;
//   }

//   .el-carousel__item:nth-child(2n + 1) {
//     background-color: #d3dce6;
//   }
::v-deep .el-carousel__button {
  display: block;
  opacity: 0.48;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: none;
  outline: 0;
  padding: 0;
  margin: 5px;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
}
// .el-carousel__item h3 {
//   color: #475669;
//   font-size: 14px;
//   opacity: 0.75;
//   line-height: 150px;
//   margin: 0;
// }

// .el-carousel__item:nth-child(2n) {
//    background-color: #99a9bf;
// }

// .el-carousel__item:nth-child(2n+1) {
//    background-color: #d3dce6;
// }
::v-deep .pchome.el-checkbox__label {
  display: inline-block;
  padding-left: 10px;
  line-height: 19px;
  font-size: 15px;
}
// .el-carousel__item.is-animation{
//   transition:transform 1s ease-in-out;
// }
::v-deep .h5home .el-checkbox__label {
  display: inline-block;
  padding-left: 10px;
  line-height: 19px;
  font-weight: 700;
  font-size: 17px;
}
.el-form-item {
  margin-bottom: 18px;
}
::v-deep.el-select .el-input__inner {
  border-radius: 20px;
}
::v-deep.el-input--suffix .el-input__inner {
  padding-right: 30px;
  border-radius: 20px;
}
</style>
