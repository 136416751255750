<template>
  <div>
    <div v-title data-title="Care for our Energy - A Step to Green">
      <div v-if="offsetwidth > 850">
        <img
          style="width: 100%"
          src="../assets/img/imgs/Photoalbum.jpg"
          alt=""
        />
        <div style="position: relative">
          <img style="width: 100%" src="../assets/img/white.jpg" alt="" />

          <img
            style="
              width: 25%;
              position: absolute;
              top: 50%;
              left: 50%;
              cursor: pointer;
              transform: translate(-50%, -50%);
            "
            @click="goinfo"
            src="../assets/img/transparentBtn.png"
            alt=""
          />
        </div>
        <img style="width: 100%" src="../assets/img/aboutUs.jpg" alt="" />
      </div>
      <div v-else style="width: 100%">
        <img
          style="width: 100%"
          src="../assets/img/phoneImgs/Photoalbum.jpg"
          alt=""
        />
        <div style="position: relative">
          <img style="width: 100%" src="../assets/img/phone/white.jpg" alt="" />

          <img
            style="
              width: 45%;
              position: absolute;
              top: 50%;
              left: 50%;
              cursor: pointer;
              transform: translate(-50%, -50%);
            "
            @click="goinfo"
            src="../assets/img/transparentBtn.png"
            alt=""
          />
        </div>
        <img
          style="width: 100%"
          src="../assets/img/phone/phoneAboutUs.jpg"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    offsetwidth: {
      type: Number,
    },
  },
  methods: {
    goinfo() {
      // window.sessionStorage.setItem("activeIndex", "/CustomerDetails");

      this.$emit("fun", "/gellery");
    },
  },
};
</script>

<style></style>
