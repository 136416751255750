<template>
  <div v-title data-title="GREEN SPARK">
    <div
      v-if="offsetwidth > 850"
      class="flex flex_around"
      style="margin-bottom: 20px"
    >
     
      <div
        style="
          width: 75%;
          min-width: 240px;
          max-width: 800px;
          margin-top: 50px;
          backround: #eee;
          border: 1px solid #ccc;
          border-radius: 8px;
          padding: 10px 25px;
        "
      >
        <h2 class="font_bold">Privacy Policy</h2>

        <div class="text_left font_15">
          <p>
               <span class="text_bold font_16">Green Spark</span>
            is committed to providing quality services to you and this policy
            outlines our ongoing obligations to you in respect of how we manage
            your Personal Information.
          </p>
        <p>
            We have adopted the Australian Privacy Principles (APPs) contained
            in the Privacy Act 1988 (Cth) (the Privacy Act). The NPPs govern the
            way in which we collect, use, disclose, store, secure and dispose of
            your Personal Information.
          </p>
          <p>
            A copy of the Australian Privacy Principles may be obtained from the
            website of The Office of the Australian Information Commissioner at
            www.aoic.gov.au
          </p>
          <p class="text_bold font_16">
            What is Personal Information and why do we collect it?
          </p>
          <p>
            Personal Information is information or an opinion that identifies an
            individual. Examples of Personal Information we collect include:
            names, addresses, email addresses, phone and facsimile numbers.
          </p>
          <p>
            This Personal Information is obtained in many ways including
            <span class="text_bold font_16">
              [by telephone, by email, via our website www.greenspark.com.au,
              from website, from media and publications, from cookies].
            </span>
          </p>
          <p>
            We collect your Personal Information for the primary purpose of
            providing our services to you, providing information to our clients
            and marketing. We may also use your Personal Information for
            secondary purposes closely related to the primary purpose, in
            circumstances where you would reasonably expect such use or
            disclosure. You may unsubscribe from our mailing/marketing lists at
            any time by contacting us in writing.
          </p>
          <p>
            When we collect Personal Information, we will, where appropriate and
            where possible, explain to you why we are collecting the information
            and how we plan to use it.
          </p>
          <p class="text_bold font_16">Sensitive Information</p>
          <p>
            Sensitive information is defined in the Privacy Act to include
            information or opinion about such things as an individual's racial
            or ethnic origin, political opinions, membership of a political
            association, religious or philosophical beliefs, membership of a
            trade union or other professional body, criminal record or health
            information.
          </p>
          <p>Sensitive information will be used by us only:</p>
          <ul>
            <li>For the primary purpose for which it was obtained.</li>
            <li>
              For a secondary purpose that is directly related to the primary
              purpose.
            </li>
            <li>With your consent; or where required or authorised by law.</li>
          </ul>
          <p class="text_bold font_16">Disclosure of Personal Information</p>
          <p>
            Your Personal Information may be disclosed in a number of
            circumstances including the following:
          </p>
          <ul>
            <li>
              Third parties where you consent to the use or disclosure; and
            </li>
            <li>Where required or authorised by law.</li>
          </ul>
          <p class="text_bold font_16">Security of Personal Information</p>
          <p>
            Your Personal Information is stored in a manner that reasonably
            protects it from misuse and loss and from unauthorized access,
            modification or disclosure.
          </p>
          <p>
            When your Personal Information is no longer needed for the purpose
            for which it was obtained, we will take reasonable steps to destroy
            or permanently de-identify your Personal Information. However, most
            of the Personal Information is or will be stored in client files
            which will be kept by us for a minimum of 7 years.
          </p>
          <p class="text_bold font_16">
            Access to your Personal Information
          </p>
          <p>
            You may access the Personal Information we hold about you and to
            update and/or correct it, subject to certain exceptions. If you wish
            to access your Personal Information, please contact us in writing.
          </p>
          <p>
            Green Spark Pty Ltd will not charge any fee for your access request
            but may charge an administrative fee for providing a copy of your
            Personal Information.
          </p>
          <p>
            In order to protect your Personal Information, we may require
            identification from you before releasing the requested information.
          </p>
          <p class="text_bold font_16">
            Maintaining the Quality of your Personal Information
          </p>
          <p>
            It is important to us that your Personal Information is up to date.
            We will take reasonable steps to make sure that your Personal
            Information is accurate, complete, and up to date. If you find that
            the information we have is not up to date or is inaccurate, please
            advise us as soon as practicable so we can update our records and
            ensure we can continue to provide quality services to you.
          </p>
          <p class="text_bold font_16">Policy Updates</p>
          <p>
            This Policy may change from time to time and is available on our
            website.
          </p>
          <p class="text_bold font_16">
            Privacy Policy Complaints and Enquiries
          </p>
          <p>
            If you have any queries or complaints about our Privacy Policy,
            please contact us at:
          </p>
          <p class="text_bold font_16">Green Spark Pty Ltd</p>
          <p class="text_bold font_16">
            11 Howleys Rd, Notting Hill VIC 3168
          </p>
          <p class="text_bold font_16">(03) 85281831</p>
        </div>
      </div>
    </div>
    <div v-else class="flex flex_around" style="margin-bottom: 20px">
      <div
        style="
          width: 90%;
          min-width: 240px;
          max-width: 800px;
          margin-top: 10px;
          backround: #eee;
          border: 1px solid #ccc;
          border-radius: 8px;
          padding: 10px 15px;
        "
      >
         <h2 class="font_bold">Privacy Policy</h2>

        <div class="text_left font_15">
          <p>
               <span class="text_bold font_16">Green Spark</span>
            is committed to providing quality services to you and this policy
            outlines our ongoing obligations to you in respect of how we manage
            your Personal Information.
          </p>
        <p>
            We have adopted the Australian Privacy Principles (APPs) contained
            in the Privacy Act 1988 (Cth) (the Privacy Act). The NPPs govern the
            way in which we collect, use, disclose, store, secure and dispose of
            your Personal Information.
          </p>
          <p>
            A copy of the Australian Privacy Principles may be obtained from the
            website of The Office of the Australian Information Commissioner at
            www.aoic.gov.au
          </p>
          <p class="text_bold font_16">
            What is Personal Information and why do we collect it?
          </p>
          <p>
            Personal Information is information or an opinion that identifies an
            individual. Examples of Personal Information we collect include:
            names, addresses, email addresses, phone and facsimile numbers.
          </p>
          <p>
            This Personal Information is obtained in many ways including
            <span class="text_bold font_16">
              [by telephone, by email, via our website www.greenspark.com.au,
              from website, from media and publications, from cookies].
            </span>
          </p>
          <p>
            We collect your Personal Information for the primary purpose of
            providing our services to you, providing information to our clients
            and marketing. We may also use your Personal Information for
            secondary purposes closely related to the primary purpose, in
            circumstances where you would reasonably expect such use or
            disclosure. You may unsubscribe from our mailing/marketing lists at
            any time by contacting us in writing.
          </p>
          <p>
            When we collect Personal Information, we will, where appropriate and
            where possible, explain to you why we are collecting the information
            and how we plan to use it.
          </p>
          <p class="text_bold font_16">Sensitive Information</p>
          <p>
            Sensitive information is defined in the Privacy Act to include
            information or opinion about such things as an individual's racial
            or ethnic origin, political opinions, membership of a political
            association, religious or philosophical beliefs, membership of a
            trade union or other professional body, criminal record or health
            information.
          </p>
          <p>Sensitive information will be used by us only:</p>
          <ul>
            <li>For the primary purpose for which it was obtained.</li>
            <li>
              For a secondary purpose that is directly related to the primary
              purpose.
            </li>
            <li>With your consent; or where required or authorised by law.</li>
          </ul>
          <p class="text_bold font_16">Disclosure of Personal Information</p>
          <p>
            Your Personal Information may be disclosed in a number of
            circumstances including the following:
          </p>
          <ul>
            <li>
              Third parties where you consent to the use or disclosure; and
            </li>
            <li>Where required or authorised by law.</li>
          </ul>
          <p class="text_bold font_16">Security of Personal Information</p>
          <p>
            Your Personal Information is stored in a manner that reasonably
            protects it from misuse and loss and from unauthorized access,
            modification or disclosure.
          </p>
          <p>
            When your Personal Information is no longer needed for the purpose
            for which it was obtained, we will take reasonable steps to destroy
            or permanently de-identify your Personal Information. However, most
            of the Personal Information is or will be stored in client files
            which will be kept by us for a minimum of 7 years.
          </p>
          <p class="text_bold font_16">
            Access to your Personal Information
          </p>
          <p>
            You may access the Personal Information we hold about you and to
            update and/or correct it, subject to certain exceptions. If you wish
            to access your Personal Information, please contact us in writing.
          </p>
          <p>
            Green Spark Pty Ltd will not charge any fee for your access request
            but may charge an administrative fee for providing a copy of your
            Personal Information.
          </p>
          <p>
            In order to protect your Personal Information, we may require
            identification from you before releasing the requested information.
          </p>
          <p class="text_bold font_16">
            Maintaining the Quality of your Personal Information
          </p>
          <p>
            It is important to us that your Personal Information is up to date.
            We will take reasonable steps to make sure that your Personal
            Information is accurate, complete, and up to date. If you find that
            the information we have is not up to date or is inaccurate, please
            advise us as soon as practicable so we can update our records and
            ensure we can continue to provide quality services to you.
          </p>
          <p class="text_bold font_16">Policy Updates</p>
          <p>
            This Policy may change from time to time and is available on our
            website.
          </p>
          <p class="text_bold font_16">
            Privacy Policy Complaints and Enquiries
          </p>
          <p>
            If you have any queries or complaints about our Privacy Policy,
            please contact us at:
          </p>
          <p class="text_bold font_16">Green Spark Pty Ltd</p>
          <p class="text_bold font_16">
            11 Howleys Rd, Notting Hill VIC 3168
          </p>
          <p class="text_bold font_16">(03) 85281831</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    offsetwidth: {
      type: Number,
    },
  },
};
</script>

<style>
.font_15 {
  font-size: 15px;
}
.font_16 {
  font-size: 16px;
}
.text_bold {
  font-weight: 700;
}
.text_left {
  text-align: left;
}
.text_center {
  text-align: center;
}
</style>
<style scoped>
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}
.flex {
  display: flex;
}
.flex_around {
  display: flex;
  justify-content: space-around;
}
</style>
