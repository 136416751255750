<template>
  <div v-title data-title="GREEN SPARK">
    <div v-if="offsetwidth > 850" style=" border-style: solid; border-width: 5px; border-color: transparent;">
      <img style="width: 100%" src="../assets/imgTwo/PC/products.png" alt="" />
    </div>
    <div v-else style="width: 100%">
      <img
        style="width: 100%"
        src="../assets/imgTwo/H5/produts.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    offsetwidth: {
      type: Number,
    },
  },
  mounted() {
    window.fbq("track", "ViewContent", {
      content_type: "Products",
      content_ids: ["10003"],
      content_name: "Products",
      content_category: "Products",
    });
  },
  methods: {
    goinfo() {
      // window.sessionStorage.setItem("activeIndex", "/CustomerDetails");

      this.$emit("fun", "/CustomerDetails");
    },
  },
};
</script>

<style></style>
