<template>
  <div v-title data-title="GREEN SPARK">
    <!-- style=" border-style: solid; border-width: 5px; border-color: transparent;" -->
    <div v-if="offsetwidth > 850">
      <img style="width: 100%" src="../assets/imgthree/PC/Privacy.png" alt="" />
      <div style="display: flex; justify-content: center">
        <div
          style="
            width: 100%;
            min-width: 800px;
            max-width: 800px;
            margin-top: 50px;
            backround: #eee;
            text-align: left;
          "
        >
          <span class="text_bold font_16"
            >Astep Group Pty Ltd ACN 158 072 041 trading as Green Spark
            Energy</span
          >
          <div class="text_left font_15">
            <span class="text_bold font_16">1. We respect your privacy</span>
            <p>
              <span
                >(a) Astep Group Pty Ltd respects your right to privacy and is
                committed to safeguarding the privacy of our customers and
                website visitors. We adhere to the Australian Privacy Principles
                contained in the Privacy Act 1988 (Cth). This policy sets out
                how we collect and treat your personal information.</span
              >
            </p>
            <p>
              (b) “Personal information” is information we hold which is
              identifiable as being about you.
            </p>
            <span class="text_bold font_16"
              >2. Collection of personal information</span
            >
            <p>
              (a) Green Spark Energy will, from time to time, receive and store
              personal information you enter onto our website, provided to us
              directly or given to us in other forms.
            </p>
            <p>
              (b) You may provide basic information such as your name, phone
              number, address and email address to enable us to send
              information, provide updates and process your product or service
              order. We may collect additional information at other times,
              including but not limited to, when you provide feedback, when you
              provide information about your personal or business affairs,
              change your content or email preference, respond to surveys and/or
              promotions, provide financial or credit card information, or
              communicate with our customer support.
            </p>
            <p>
              (c) Additionally, we may also collect any other information you
              provide while interacting with us.
            </p>
            <span class="text_bold font_16"
              >3. How we collect your personal information</span
            >
            <p>
              (a) Astep Group Pty Ltd collects personal information from you in
              a variety of ways, including when you interact with us
              electronically or in person, when you access our website and when
              we provide our services to you. We may receive personal
              information from third parties. If we do, we will protect it as
              set out in this Privacy Policy.
            </p>
            <span class="text_bold font_16"
              >4. Use of your personal information</span
            >
            <p>
              (a) Astep Group Pty Ltd may use personal information collected
              from you to provide you with information, updates and our
              services. We may also make you aware of new and additional
              products, services and opportunities available to you. We may use
              your personal information to improve our products and services and
              better understand your needs.
            </p>
            <p>
              (b) Astep Group Pty Ltd may contact you by a variety of measures
              including, but not limited to telephone, email, sms or mail.
            </p>
            <span class="text_bold font_16"
              >5. Disclosure of your personal information</span
            >
            <p>
              (a) We may disclose your personal information to any of our
              employees, officers, insurers, professional advisers, agents,
              suppliers or subcontractors insofar as reasonably necessary for
              the purposes set out in this Policy. Personal information is only
              supplied to a third party when it is required for the delivery of
              our services.
            </p>
            <p>
              (b) We may from time to time need to disclose personal information
              to comply with a legal requirement, such as a law, regulation,
              court order, subpoena, warrant, in the course of a legal
              proceeding or in response to a law enforcement agency request.
            </p>
            <p>
              (c) We may also use your personal information to protect the
              copyright, trademarks, legal rights, property or safety of Astep
              Group Pty Ltd, its customers or third parties.
            </p>
            <p>
              (d) Information that we collect may from time to time be stored,
              processed in or transferred between parties located in countries
              outside of Australia. Such information may include, but is not
              limited to:
            </p>
            <p>(i) your name;</p>
            <p>
              (ii) your contact details, including email address, mailing
              address, street address and/or telephone number;
            </p>
            <p>
              (iii) information you provide to us when submitting an enquiry
              through our contact form;
            </p>
            <p>(iv) your credit card or payment details;</p>
            <p>
              (v) details of services we have provided to you and/or that you
              have enquired about, and our response to you;
            </p>
            <p>
              (vi) your browser session and geo-location data, device and
              network information, statistics on page views and sessions,
              acquisition sources, search queries and/or browsing behaviour;
            </p>
            <p>
              (vii) information about your access and use of our website,
              including through the use of Internet cookies, your communications
              with our website, the type of browser you are using, the type of
              operating system you are using and the domain name of your
              Internet service provider;
            </p>
            <p>
              (viii) additional personal information that you provide to us,
              directly or indirectly, through your use of our Site, associated
              applications, accounts from which you permit us to collect
              information, or in the course of providing services to you; and
            </p>
            <p>
              (ix) any other personal information requested by us and/or
              provided by you or a third party.
            </p>
            <p>
              (e) If there is a change of control in our business or a sale or
              transfer of business assets, we reserve the right to transfer to
              the extent permissible at law our user databases, together with
              any personal information and non-personal information contained in
              those databases. This information may be disclosed to a potential
              purchaser under an agreement to maintain confidentiality. We would
              seek to only disclose information in good faith and where required
              by any of the above circumstances.
            </p>
            <p>
              (f) By providing us with personal information, you consent to the
              terms of this Privacy Policy and the types of disclosure covered
              by this Policy. Where we disclose your personal information to
              third parties, we will request that the third party follow this
              Policy regarding handling your personal information.
            </p>
            <span class="text_bold font_16"
              >6. Security of your personal information</span
            >
            <p>
              (a) Astep Group Pty Ltd is committed to ensuring that the
              information you provide to us is secure. In order to prevent
              unauthorised access or disclosure, we have put in place suitable
              physical, electronic and managerial procedures to safeguard and
              secure information and protect it from misuse, interference, loss
              and unauthorised access, modification and disclosure.
            </p>
            <p>
              (b) The transmission and exchange of information is carried out at
              your own risk. We cannot guarantee the security of any information
              that you transmit to us, or receive from us. Although we take
              measures to safeguard against unauthorised disclosures of
              information, we cannot assure you that personal information that
              we collect will not be disclosed in a manner that is inconsistent
              with this Privacy Policy.
            </p>
            <span class="text_bold font_16"
              >7. Access to your personal information</span
            >
            <p>
              (a) You may request details of personal information that we hold
              about you in accordance with the provisions of the Privacy Act
              1988 (Cth). A small administrative fee may be payable for the
              provision of information. If you would like a copy of the
              information, which we hold about you or believe that any
              information we hold on you is inaccurate, o of date, incomplete,
              irrelevant or misleading, please email us at
              greensparkau@gmail.com
            </p>
            <p>
              (b) We reserve the right to refuse to provide you with information
              that we hold about you, in certain circumstances set out in the
              Privacy Act.
            </p>
            <span class="text_bold font_16">8. Complaints about privacy</span>
            <p>
              (a) If you have any complaints about our privacy practises, please
              feel free to send in details of your complaints to 11 Howleys Road
              , Notting Hill, Victoria, 3168. We take complaints very seriously
              and will respond shortly after receiving written notice of your
              complaint.
            </p>
            <span class="text_bold font_16">9. Changes to Privacy Policy</span>
            <p>
              (a) Please be aware that we may change this Privacy Policy in the
              future. We may modify this Policy at any time, in our sole
              discretion and all modifications will be effective immediately
              upon our posting of the modifications on our website or notice
              board. Please check back from time to time to review our Privacy
              Policy.
            </p>
            <span class="text_bold font_16">10. Website</span>
            <span class="text_bold font_16"
              >(a) When you visit our website</span
            >
            <p>
              When you come to our website https://greenspark-energy.com.au/ we
              may collect certain information such as browser type, operating
              system, website visited immediately before coming to our site,
              etc. This information is used in an aggregated manner to analyse
              how people use our site, such that we can improve our service.
            </p>
            <span class="text_bold font_16">(b) Cookies</span>
            <p>
              We may from time to time use cookies on our website. Cookies are
              very small files which a website uses to identify you when you
              come back to the site and to store details about your use of the
              site. Cookies are not malicious programs that access or damage
              your computer. Most web browsers automatically accept cookies but
              you can choose to reject cookies by changing your browser
              settings. However, this may prevent you from taking full advantage
              of our website. Our website may from time to time use cookies to
              analyses website traffic and help us provide a better website
              visitor experience. In addition, cookies may be used to serve
              relevant ads to website visitors through third party services such
              as Google Adwords. These ads may appear on this website or other
              websites you visit.
            </p>
            <span class="text_bold font_16">(c) Third party sites</span>
            <p>
              Our site may from time to time have links to other websites not
              owned or controlled by us. These links are meant for your
              convenience only. Links to third party websites do not constitute
              sponsorship or endorsement or approval of these websites. Please
              be aware that Astep Group Pty Ltd is not responsible for the
              privacy practises of other such websites. We encourage our users
              to be aware, when they leave our website, to read the privacy
              statements of each and every website that collects personal
              identifiable information.
            </p>
            <span class="text_bold font_16"
              >For any questions and notices, please contact us at:</span
            >
            <p>
              Astep Group Pty Ltd ACN 158 072 041 trading as Green Spark Energy
            </p>
            <p >2/344 fern tree gully rd, Notting Hill</p>
            <p>Email:greensparkau@gmail.com</p>
            <p>Telephone: +61 03 8824 0623</p>
          </div>
        </div>
      </div>
      <!-- <img style="width: 100%" src="../assets/imgthree/PC/pp.jpg" alt="" /> -->
      <div style="position: relative">
        <img
          style="width: 100%"
          src="../assets/imgthree/PC/join-now背景.jpg"
          alt=""
        />

        <img
          style="
            width: 25%;
            position: absolute;
            top: 60%;
            left: 50%;
            cursor: pointer;
            transform: translate(-50%, -50%);
          "
          @click="goinfo"
          src="../assets/imgthree/PC/join now.png"
          alt=""
        />
      </div>
      <img style="width: 100%" src="../assets/imgthree/PCNEW/底部.jpg" alt="" />
    </div>
    <div v-else style="margin-bottom: 20px">
      <img style="width: 100%" src="../assets/imgthree/H5/privacy.jpg" alt="" />
         <div style="display: flex; justify-content: center">
        <div
          class="flex flex_around"
        style="
          width: 100%;
          min-width: 240px;
          max-width: 800px;
          margin-top: 10px;
          backround: #eee;
          padding: 10px 15px;
          text-align: left;
        "
        >
          <span class="text_bold font_16"
            >Astep Group Pty Ltd ACN 158 072 041 trading as Green Spark
            Energy</span
          >
          <div class="text_left font_15">
            <span class="text_bold font_16">1. We respect your privacy</span>
            <p>
              <span
                >(a) Astep Group Pty Ltd respects your right to privacy and is
                committed to safeguarding the privacy of our customers and
                website visitors. We adhere to the Australian Privacy Principles
                contained in the Privacy Act 1988 (Cth). This policy sets out
                how we collect and treat your personal information.</span
              >
            </p>
            <p>
              (b) “Personal information” is information we hold which is
              identifiable as being about you.
            </p>
            <span class="text_bold font_16"
              >2. Collection of personal information</span
            >
            <p>
              (a) Green Spark Energy will, from time to time, receive and store
              personal information you enter onto our website, provided to us
              directly or given to us in other forms.
            </p>
            <p>
              (b) You may provide basic information such as your name, phone
              number, address and email address to enable us to send
              information, provide updates and process your product or service
              order. We may collect additional information at other times,
              including but not limited to, when you provide feedback, when you
              provide information about your personal or business affairs,
              change your content or email preference, respond to surveys and/or
              promotions, provide financial or credit card information, or
              communicate with our customer support.
            </p>
            <p>
              (c) Additionally, we may also collect any other information you
              provide while interacting with us.
            </p>
            <span class="text_bold font_16"
              >3. How we collect your personal information</span
            >
            <p>
              (a) Astep Group Pty Ltd collects personal information from you in
              a variety of ways, including when you interact with us
              electronically or in person, when you access our website and when
              we provide our services to you. We may receive personal
              information from third parties. If we do, we will protect it as
              set out in this Privacy Policy.
            </p>
            <span class="text_bold font_16"
              >4. Use of your personal information</span
            >
            <p>
              (a) Astep Group Pty Ltd may use personal information collected
              from you to provide you with information, updates and our
              services. We may also make you aware of new and additional
              products, services and opportunities available to you. We may use
              your personal information to improve our products and services and
              better understand your needs.
            </p>
            <p>
              (b) Astep Group Pty Ltd may contact you by a variety of measures
              including, but not limited to telephone, email, sms or mail.
            </p>
            <span class="text_bold font_16"
              >5. Disclosure of your personal information</span
            >
            <p>
              (a) We may disclose your personal information to any of our
              employees, officers, insurers, professional advisers, agents,
              suppliers or subcontractors insofar as reasonably necessary for
              the purposes set out in this Policy. Personal information is only
              supplied to a third party when it is required for the delivery of
              our services.
            </p>
            <p>
              (b) We may from time to time need to disclose personal information
              to comply with a legal requirement, such as a law, regulation,
              court order, subpoena, warrant, in the course of a legal
              proceeding or in response to a law enforcement agency request.
            </p>
            <p>
              (c) We may also use your personal information to protect the
              copyright, trademarks, legal rights, property or safety of Astep
              Group Pty Ltd, its customers or third parties.
            </p>
            <p>
              (d) Information that we collect may from time to time be stored,
              processed in or transferred between parties located in countries
              outside of Australia. Such information may include, but is not
              limited to:
            </p>
            <p>(i) your name;</p>
            <p>
              (ii) your contact details, including email address, mailing
              address, street address and/or telephone number;
            </p>
            <p>
              (iii) information you provide to us when submitting an enquiry
              through our contact form;
            </p>
            <p>(iv) your credit card or payment details;</p>
            <p>
              (v) details of services we have provided to you and/or that you
              have enquired about, and our response to you;
            </p>
            <p>
              (vi) your browser session and geo-location data, device and
              network information, statistics on page views and sessions,
              acquisition sources, search queries and/or browsing behaviour;
            </p>
            <p>
              (vii) information about your access and use of our website,
              including through the use of Internet cookies, your communications
              with our website, the type of browser you are using, the type of
              operating system you are using and the domain name of your
              Internet service provider;
            </p>
            <p>
              (viii) additional personal information that you provide to us,
              directly or indirectly, through your use of our Site, associated
              applications, accounts from which you permit us to collect
              information, or in the course of providing services to you; and
            </p>
            <p>
              (ix) any other personal information requested by us and/or
              provided by you or a third party.
            </p>
            <p>
              (e) If there is a change of control in our business or a sale or
              transfer of business assets, we reserve the right to transfer to
              the extent permissible at law our user databases, together with
              any personal information and non-personal information contained in
              those databases. This information may be disclosed to a potential
              purchaser under an agreement to maintain confidentiality. We would
              seek to only disclose information in good faith and where required
              by any of the above circumstances.
            </p>
            <p>
              (f) By providing us with personal information, you consent to the
              terms of this Privacy Policy and the types of disclosure covered
              by this Policy. Where we disclose your personal information to
              third parties, we will request that the third party follow this
              Policy regarding handling your personal information.
            </p>
            <span class="text_bold font_16"
              >6. Security of your personal information</span
            >
            <p>
              (a) Astep Group Pty Ltd is committed to ensuring that the
              information you provide to us is secure. In order to prevent
              unauthorised access or disclosure, we have put in place suitable
              physical, electronic and managerial procedures to safeguard and
              secure information and protect it from misuse, interference, loss
              and unauthorised access, modification and disclosure.
            </p>
            <p>
              (b) The transmission and exchange of information is carried out at
              your own risk. We cannot guarantee the security of any information
              that you transmit to us, or receive from us. Although we take
              measures to safeguard against unauthorised disclosures of
              information, we cannot assure you that personal information that
              we collect will not be disclosed in a manner that is inconsistent
              with this Privacy Policy.
            </p>
            <span class="text_bold font_16"
              >7. Access to your personal information</span
            >
            <p>
              (a) You may request details of personal information that we hold
              about you in accordance with the provisions of the Privacy Act
              1988 (Cth). A small administrative fee may be payable for the
              provision of information. If you would like a copy of the
              information, which we hold about you or believe that any
              information we hold on you is inaccurate, o of date, incomplete,
              irrelevant or misleading, please email us at
              greensparkau@gmail.com
            </p>
            <p>
              (b) We reserve the right to refuse to provide you with information
              that we hold about you, in certain circumstances set out in the
              Privacy Act.
            </p>
            <span class="text_bold font_16">8. Complaints about privacy</span>
            <p>
              (a) If you have any complaints about our privacy practises, please
              feel free to send in details of your complaints to 11 Howleys Road
              , Notting Hill, Victoria, 3168. We take complaints very seriously
              and will respond shortly after receiving written notice of your
              complaint.
            </p>
            <span class="text_bold font_16">9. Changes to Privacy Policy</span>
            <p>
              (a) Please be aware that we may change this Privacy Policy in the
              future. We may modify this Policy at any time, in our sole
              discretion and all modifications will be effective immediately
              upon our posting of the modifications on our website or notice
              board. Please check back from time to time to review our Privacy
              Policy.
            </p>
            <span class="text_bold font_16">10. Website</span>
            <span class="text_bold font_16"
              >(a) When you visit our website</span
            >
            <p>
              When you come to our website https://greenspark-energy.com.au/ we
              may collect certain information such as browser type, operating
              system, website visited immediately before coming to our site,
              etc. This information is used in an aggregated manner to analyse
              how people use our site, such that we can improve our service.
            </p>
            <span class="text_bold font_16">(b) Cookies</span>
            <p>
              We may from time to time use cookies on our website. Cookies are
              very small files which a website uses to identify you when you
              come back to the site and to store details about your use of the
              site. Cookies are not malicious programs that access or damage
              your computer. Most web browsers automatically accept cookies but
              you can choose to reject cookies by changing your browser
              settings. However, this may prevent you from taking full advantage
              of our website. Our website may from time to time use cookies to
              analyses website traffic and help us provide a better website
              visitor experience. In addition, cookies may be used to serve
              relevant ads to website visitors through third party services such
              as Google Adwords. These ads may appear on this website or other
              websites you visit.
            </p>
            <span class="text_bold font_16">(c) Third party sites</span>
            <p>
              Our site may from time to time have links to other websites not
              owned or controlled by us. These links are meant for your
              convenience only. Links to third party websites do not constitute
              sponsorship or endorsement or approval of these websites. Please
              be aware that Astep Group Pty Ltd is not responsible for the
              privacy practises of other such websites. We encourage our users
              to be aware, when they leave our website, to read the privacy
              statements of each and every website that collects personal
              identifiable information.
            </p>
            <span class="text_bold font_16"
              >For any questions and notices, please contact us at:</span
            >
            <p>
              Astep Group Pty Ltd ACN 158 072 041 trading as Green Spark Energy
            </p>
            <p >2/344 fern tree gully rd, Notting Hill</p>
            <p >Email:greensparkau@gmail.com</p>
            <p>Telephone: +61 03 8824 0623</p>
          </div>
        </div>
      </div>
      <div style="position: relative">
        <img
          style="width: 100%"
          src="../assets/imgthree/H5/背景图.jpg"
          alt=""
        />

        <img
          style="
            width: 65%;
            position: absolute;
            top: 70%;
            left: 50%;
            cursor: pointer;
            transform: translate(-50%, -50%);
          "
          @click="goinfo"
          src="../assets/imgthree/H5/join now.png"
          alt=""
        />
      </div>
      <img style="width: 100%" src="../assets/imgthree/H5NEW/底部手机版.jpg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    offsetwidth: {
      type: Number,
    },
  },
  mounted() {
    window.fbq("track", "ViewContent", {
      content_type: "Contact Us",
      content_ids: ["10005"],
      content_name: "Contact Us",
      content_category: "Contact Us",
    });
  },
  methods: {
    goinfo() {
      // window.sessionStorage.setItem("activeIndex", "/CustomerDetails");

      this.$emit("fun", "/CustomerDetails");
    },
  },
};
</script>

<style>
.text_bold {
  font-weight: 700;
}
</style>
