import { render, staticRenderFns } from "./privacypolicy copy.vue?vue&type=template&id=6e40f1a6&scoped=true&"
import script from "./privacypolicy copy.vue?vue&type=script&lang=js&"
export * from "./privacypolicy copy.vue?vue&type=script&lang=js&"
import style0 from "./privacypolicy copy.vue?vue&type=style&index=0&lang=css&"
import style1 from "./privacypolicy copy.vue?vue&type=style&index=1&id=6e40f1a6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e40f1a6",
  null
  
)

export default component.exports